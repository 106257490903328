<template>
  <v-container class="login" fill-height fluid>
    <v-row align="center" justify="center">
      <v-col></v-col>
      <v-col cols="12" sm="3">
        <v-img :src="url+'icons/logo-d+.png'" :height="32" :max-width="200" contain />
        <login/>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-spacer/>
  </v-container>
</template>

<script>
import login from '../components/global/login'
export default {
  name: 'Login',
  components: {
    login
  },
  computed: {
    url () {
      return process.env.VUE_APP_CLIENT_PROTOCOL + process.env.VUE_APP_CLIENT_URL;
    }
  }
}
</script>
<style lang="scss">
.login {
  height: 100vh;
}
</style>
